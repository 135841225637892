<template>

  <div id="v-model-select" class="demo">
    <select v-model="designLevel">
      <option disabled value="">Please select one</option>
      <option value="entry_level">Entry Level</option>
      <option value="mid_range">Mid Range</option>
      <option value="high_end">High End</option>
    </select>
    <span>Selected: {{ designLevel }}</span>
  </div>

  <span>Total Cost Factor: {{ totalCost }}</span>


  <h1>Rehab Categories</h1>
  <div class="categories">
    <RehabCategory 
        v-for="(category_data, categoryIdx) in categories" 
        :key="category_data.name" 
        :categoryIdx="categoryIdx" 
        :designLevel="designLevel"
    />
  </div>
</template>

<script>
import RehabCategory from '@/components/RehabCategory.vue'

export default {
  name: 'RehabList',
  components: {
    RehabCategory
  },
  created() {
      this.$store.dispatch('loadCategories')
  },
  data() {
    return {
      designLevel: 'entry_level',
    }
  },
  computed: {
    categories() {
      return this.$store.state.categories
    },
    totalCost() {
      let cost = 0
      let result = {}
      for (let resultIdx in this.$store.state.category_results) {
        result = this.$store.state.category_results[resultIdx]
        cost += result.cost
      }
      return cost
    }
  },
}
</script>

<style scoped>
.categories {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>