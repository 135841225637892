<template>

    <div class="category-data">
      <h3>{{ this.gui_name }}</h3>

      <RadioGroup 
          :rehabLevels="this.categoryData.rehab_level" 
          :categoryIdx="categoryIdx"
          v-model:rehab-level-idx="rehabLevelIdx"
      />

      <br>
      <p>Design Level: {{this.designLevel}}</p>
      <p>Rehab Level: {{rehabLevelLabel(this.rehab_level)}}</p>
      <p>Design Level Cost / sqft: {{designLevelCost}}</p>
      <p>Rehab Level cost / sqft modifier: {{rehabLevelModifier}}</p>
      <p>Cost / sqft: {{finalCost}}</p>

      <span>Rehab Level Data:{{ this.rehab_level }}</span>

      <br>
    </div>
</template>

<script>
import RadioGroup from '@/components/RadioGroup.vue'

export default {
  components: {
    RadioGroup
  },
  data(){
    return {
      rehabLevelIdx: '0',
    }
  },
  props: {
    categoryIdx: {
      type: Number,
      required: true
    },
    designLevel: {
      type: String,
      required: true
    },
  },  
  computed: {
    categoryData() {
      return this.$store.state.categories[this.categoryIdx]
    },
    rehab_level() {
      return this.categoryData.rehab_level[this.rehabLevelIdx]
    },
    designLevelCost() {
      return this.categoryData[this.designLevel + "_cost"]
    },
    rehabLevelModifier() {
      return this.rehab_level[this.designLevel + "_percentage"]
    },
    finalCost() {
      let cost = this.designLevelCost * this.rehabLevelModifier/100
      this.$store.dispatch('setCost', {
        categoryIdx: this.categoryIdx, 
        cost: cost
      })
      return cost
    },
    gui_name() {
      let sentence = this.categoryData.gui_override_name.toLowerCase().split("_");
      for (let i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
      }      
      return sentence.join(" ");
    }
  },
  methods: {
    rehabLevelLabel(rehabLevel) {
      if (rehabLevel.gui_override_label !== undefined) {
        return rehabLevel.gui_override_label        
      }
      else {
        return rehabLevel.label
      }
    }
  }
}
</script>

<style scoped>
.category-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>