<template>
  <div id="v-model-radiobutton" class="radio-group">

    <div v-for="(rehabLevel, itemObjKey)  in rehabLevels" :key="itemObjKey">
      <input 
          type="radio" 
          :id="'radio_'+categoryIdx+'_'+itemObjKey" 
          :value="itemObjKey" 
          v-model="picked"
          @input="$emit('update:rehabLevelIdx', $event.target.value)"          
      />

      <span>  --</span> 
      <label :for="'radio_'+categoryIdx+'_'+itemObjKey">{{ rehabLevelLabel(rehabLevel) }}</label>
      <br />
    
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      picked: '0'
    }
  }, 
  props: {
    rehabLevels: Array,
    categoryIdx: Number,
    rehabLevelIdx: String
  },
  emits: ['update:rehabLevelIdx'],
  methods: {
    rehabLevelLabel(rehabLevel) {
      if (rehabLevel.gui_override_label !== undefined) {
        return rehabLevel.gui_override_label        
      }
      else {
        return rehabLevel.label
      }
    }
  }
}
</script>

<style lang="postcss" scoped>
.radio-group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: space-around;
}
</style>