import { createStore } from 'vuex'
import EventService from '@/services/EventService.js'
import json from '../data/rehab_components.json'

export default createStore({
  state: {
    user: 'Adam Jahr',
    events: [],
    event: {},
    categories: [],
    category_results: [],
  },
  mutations: {
    ADD_EVENT(state, event) {
      state.events.push(event)
    },
    SET_EVENT(state, event) {
      state.event = event
    },
    SET_EVENTS(state, events) {
      state.events = events
    },

    SET_CATEGORIES(state, categories) {
      state.categories = categories
      for (let i = 0; i < categories.length; i++) {
        state.category_results.push({})
      }
    },
    SET_COST(state, payload) {
      state.category_results[payload.categoryIdx].cost = payload.cost
    }
  },
  actions: {
    createEvent({ commit }, event) {
      return EventService.postEvent(event)
		  .then(() => {
		    commit('ADD_EVENT', event)
	    })
	    .catch(error => {
		    throw(error)
	    })
    },
    fetchEvents({ commit }) {
      return EventService.getEvents()
        .then(response => {
          commit('SET_EVENTS', response.data)
        })
        .catch(error => {
          throw(error)
        })
    },
    fetchEvent({ commit, state }, id) {  
      const existingEvent = state.events.find(event => event.id === id)
        if (existingEvent) {
          commit('SET_EVENT', existingEvent)
        } else {
        return EventService.getEvent(id)
        .then(response => {
          commit('SET_EVENT', response.data)
        })
        .catch(error => {
          throw(error)
        })
      }
    },

    loadCategories({ commit }) {
      commit('SET_CATEGORIES', json.components)
    },
    setCost({ commit }, payload) {
      commit('SET_COST', payload)
    }
  },
  modules: {}
})